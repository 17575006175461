<template>
  <!-- mobile移动端数据系统首页 -->
  <div class="">
    <div class="indexData">
      <el-card class="box-card">
        <div class="indexdata-top flex">
          <div class="index-top-left">
            <!-- monthrange -->

            <div class="alcenter">
              <div v-if="radio1 == 0">
                <el-date-picker
                  v-model="date"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="YYYY-MM-DD"
                  :default-value="new Date()"
                  value-format="YYYY-MM-DD"
                  @change="dateChange"
                >
                </el-date-picker>
              </div>
              <div v-if="radio1 == 1">
                <el-date-picker
                  v-model="date"
                  type="monthrange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="YYYY-MM"
                  :default-value="new Date()"
                  value-format="YYYY-MM"
                  @change="dateChange"
                >
                </el-date-picker>
              </div>
              <div style="margin-left:30px">
                <el-select
                  v-model="type"
                  @change="typehange"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="margin-left:30px">
                <el-radio-group
                  v-model="radio1"
                  size="medium"
                  @change="typehanges"
                >
                  <el-radio-button label="0">日统计</el-radio-button>
                  <el-radio-button label="1" v-if="flag == 1"
                    >月统计</el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
            <div class="datashou">
              <el-card class="box-card">
                <div class="data-car">
                  <p v-if="type != 5">
                    至今回本：<span v-if="totalDatas"
                      >{{
                        ((totalDatas.amount / totalDatas.cost) * 100).toFixed(
                          2
                        )
                      }}%</span
                    >
                  </p>
                  <p v-if="type == 5">
                    至今回本：<span v-if="totalDatas"
                      >{{
                        totalDatas.amount - totalDatas.cost > 0
                          ? (
                              (((totalDatas.amount - totalDatas.cost) / 2 +
                                totalDatas.cost) /
                                totalDatas.cost) *
                              100
                            ).toFixed(2)
                          : (
                              (totalDatas.amount / totalDatas.cost) *
                              100
                            ).toFixed(2)
                      }}%</span
                    >
                  </p>
                  <p v-if="radio1 == 0">
                    总付费成本：<span>{{ getPayCostDate.toFixed(2) }}</span>
                  </p>
                </div>
                <div class="data-carfor">
                  <el-tag type="checked" effect="plain">
                    消耗：
                    <p v-if="totalDatas">{{ totalDatas.cost }}</p>
                  </el-tag>
                  <el-tag type="success" effect="plain">
                    充值：
                    <!-- 除了海外页面的充值 -->
                    <p v-if="totalDatas&& type!=6">{{ totalDatas.amount }}</p>
                    <!-- 海外页面的充值 -->
                    <p v-if="totalDatas&& type==6">{{ (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?totalDatas.amount:totalDatas.amount60 }}</p>
                  </el-tag>
                  <el-tag type="danger" effect="plain">
                    利润：
                    <!-- 除开海外和奇树 的其他页面 -->
                    <p v-if="totalDatas && type != 5 && type !=6">
                      {{ (totalDatas.amount - totalDatas.cost).toFixed(2) }}
                    </p>
                    <!-- 奇树 -->
                    <p v-if="totalDatas && type == 5">
                      {{
                        totalDatas.amount - totalDatas.cost > 0
                          ? ((totalDatas.amount - totalDatas.cost) / 2).toFixed(
                              2
                            )
                          : (totalDatas.amount - totalDatas.cost).toFixed(2)
                      }}
                    </p>
                    <!-- 海外 -->
                    <p v-if="totalDatas && type ==6">
                      {{ (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?(totalDatas.amount - totalDatas.cost).toFixed(2):(totalDatas.amount60 - totalDatas.cost).toFixed(2) }}
                    </p>
                  </el-tag>

                  <div
                    style="display:inline;cursor: pointer;"
                    @click="initialDeails = true"
                  >
                    <el-tag type="warning" effect="plain">
                      首充：

                      <p v-if="totalDatas">
                        {{
                          ((firstAmount / totalDatas.cost) * 100).toFixed(2)
                        }}%
                      </p>
                      <!-- <p v-if="amounts">{{ amounts }}%</p> -->
                    </el-tag>
                  </div>
                  <br />
                  <el-tag type="checked" effect="plain">
                    进粉：
                    <p v-if="totalDatas">{{ totalDatas.users }}</p>
                  </el-tag>
                  <el-tag type="success" effect="plain">
                    粉单价：
                    <p v-if="totalDatas">
                      {{ (totalDatas.cost / totalDatas.users).toFixed(2) }}
                    </p>
                  </el-tag>
                  <el-tag type="danger" effect="plain">
                    单粉产出：
                    <!-- 海外 -->
                    <p v-if="totalDatas&&type==6">
                      {{ (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?(totalDatas.amount / totalDatas.users).toFixed(2):(totalDatas.amount60 / totalDatas.users).toFixed(2) }}
                    </p>
                    <!-- 非海外 -->
                    <p v-if="totalDatas&&type!=6">
                      {{ (totalDatas.amount / totalDatas.users).toFixed(2) }}
                    </p>
                  </el-tag>
                </div>
              </el-card>
            </div>
          </div>
          <!-- <div class="index-top-right">
            <div class="map" id="barSimple">加载中</div>
          </div> -->
        </div>
        <div class="indexdata-bot">
          <div class="indexdata-bot-top flex">
            <div @click="gatherDeailsDialogFun()">
              <el-button icon="el-icon-s-tools" circle></el-button>
            </div>
            <div class="flex ">
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="userName"
                  multiple
                  placeholder="投放人"
                >
                  <el-option
                    v-for="item in getRequestLists.userName"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="platform"
                  multiple
                  placeholder="平台"
                >
                  <el-option
                    v-for="item in getRequestLists.platform"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="indexdata-bot-select" style="width:80px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="adPlatform"
                  multiple
                  placeholder="广告平台"
                >
                  <el-option
                    v-for="item in getRequestLists.adPlatform"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="wp"
                  multiple
                  placeholder="公众号"
                >
                  <el-option
                    v-for="item in getRequestLists.wp"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="platformNames"
                  multiple
                  placeholder="书城平台"
                >
                  <el-option
                    v-for="item in getRequestLists.platformName"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="indexdata-bot-select">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="adPlatform"
                  multiple
                  placeholder="广告平台"
                >
                  <el-option
                    v-for="item in getRequestLists.adPlatform"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="bookName"
                  multiple
                  placeholder="书名"
                >
                  <el-option
                    v-for="item in getRequestLists.bookName"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="indexdata-bot-select" :style="type == 3 ? 'width:100px' : ''">

                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="typeOfAmountss"
                  multiple
                  placeholder="充值模版"
                >
                  <el-option
                    v-for="item in getRequestLists.typeOfAmount"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->
              <!-- <div class="indexdata-bot-select">
                <el-select clearable filterable v-model="recovery" multiple placeholder="回收率">
                  <el-option
                    v-for="item in recoverys"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->
              <!-- <div class="indexdata-bot-select" v-if="type == 3">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="videoTypes"
                  multiple
                  placeholder="小程序类别"
                >
                  <el-option
                    v-for="item in getRequestLists.videoType"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->

              <!-- <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="accountId"
                  multiple
                  placeholder="账户ID"
                >
                  <el-option
                    v-for="item in getRequestLists.accountId"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->
              <!-- <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="byteVersions"
                  multiple
                  placeholder="字节版本"
                >
                  <el-option
                    v-for="item in getRequestLists.byteVersion"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->

              <!-- <div class="indexdata-bot-select" style="width:90px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="partnerPlatform"
                  multiple
                  placeholder="渠道"
                >
                  <el-option
                    v-for="item in getRequestLists.partnerPlatform"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->

              <div class="indexdata-bot-select" style="width:100px">
                <el-cascader
                  v-model="groupName"
                  :options="getRequestLists.groupName"
                  :props="{ checkStrictly: true, value: 'id', label: 'label' }"
                  clearable
                  placeholder="部门"
                ></el-cascader>
              </div>
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="orderbyValue"
                  placeholder="降序字段"
                >
                  <el-option
                    v-for="item in orderby"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="searchbtn">
                <el-button
                  type="primary"
                  @click="alldataFun()"
                  icon="el-icon-search"
                  >搜索</el-button
                >
              </div>
            </div>

            <div class="bot-top-plus" @click="addDataFun" v-if="radio1 == 0">
              <el-button icon="el-icon-plus" circle></el-button>
            </div>
          </div>
          <!-- <el-table
            :data="tableData"
            height="780"
            border
            sort-orders="['ascending', 'descending']"
            @cell-click="addDetailsFun"
          > -->
          <el-table
            :data="tableData"
            height="780"
            border
            sort-orders="['ascending', 'descending']"
          >
            <el-table-column
              sortable
              label="投放人"
              prop="userName"
              width="90"
            />
            <!-- <el-table-column sortable label="账户ID" prop="accountId" width="90" /> -->
            <el-table-column
              sortable
              label="广告平台"
              prop="adPlatform"
              width="80"
            />

            <el-table-column sortable label="平台" prop="platform" width="80" />
            <!-- <el-table-column sortable label="公众号" prop="wp" width="110" /> -->
            <!-- <el-table-column sortable label="广告平台" prop="adPlatform" width="110" /> -->
            <!-- <el-table-column sortable label="充值模版" prop="typeOfAmount" width="100">
              <template #default="scope">
                {{
                  scope.row.typeOfAmount == 0
                    ? '其他'
                    : scope.row.typeOfAmount == 1
                    ? '小额'
                    : scope.row.typeOfAmount == 2
                    ? '中额'
                    : scope.row.typeOfAmount == 3
                    ? '大额'
                    : scope.row.typeOfAmount == 4
                    ? '客服消息'
                    : '图文消息'
                }}
              </template>
            </el-table-column> -->
            <el-table-column
              sortable
              label="书名"
              prop="bookName"
              width="130"
            />
            <!-- <el-table-column sortable label="粉丝" prop="newFans" width="80" /> -->
            <el-table-column sortable label="成本" prop="cost" width="110" />
            <el-table-column
              sortable
              label="充值"
              prop="totalAmount"
              width="130"
            >
              <template #default="scope">
                <!-- <span>{{
                  (scope.row.totalAmount / scope.row.cost).toFixed(2) > 0.99 &&
                  flag != 1
                    ? '已回本'
                    : scope.row.totalAmount
                }}</span> -->
                <!-- 非海外 -->
                <span v-if="type!=6" >{{ scope.row.totalAmount }}</span>
                <!-- 海外 -->
                <span v-if="type==6" >{{ (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')? scope.row.totalAmount:scope.row.amount60 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="首充"
              prop="name"
              width="80"
              v-if="radio1 == 0"
            >
              <template #default="scope">
                <span v-if="radio1 == 0"
                  >{{
                    ((scope.row.amount / scope.row.cost) * 100).toFixed(2)
                  }}%</span
                >
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="首充人数"
              prop="name"
              width="160"
              v-if="radio1 == 0"
            >
              <template #default="scope">
                <span v-if="radio1 == 0"
                  >{{
                    ((scope.row.count / scope.row.newFans) * 100).toFixed(2)
                  }}%({{ scope.row.count }}次/{{ scope.row.scrs }})人</span
                >
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="付费成本"
              prop="name"
              width="80"
              v-if="radio1 == 0"
            >
              <template #default="scope">
                <span v-if="radio1 == 0">{{
                  scope.row.scrs == 0
                    ? 0
                    : (scope.row.cost / scope.row.scrs).toFixed(2)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable label="回收率" prop="name" width="90">
              <template #default="scope">
                <!-- <span>{{
                  (scope.row.totalAmount / scope.row.cost).toFixed(2) > 0.99 &&
                  flag != 1
                    ? '已回本'
                    : ((scope.row.totalAmount / scope.row.cost) * 100).toFixed(
                        2
                      ) + '%'
                }}</span> -->
                <!-- 非海外 -->
                <span v-if="type!=6">{{
                  ((scope.row.totalAmount / scope.row.cost) * 100).toFixed(2) +
                    '%'
                }}</span>
                <!-- 海外 -->
                <span v-if="type==6">{{
                  (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?(((scope.row.totalAmount / scope.row.cost) * 100).toFixed(2))+'%':(((scope.row.amount60 / scope.row.cost) * 100).toFixed(2)) +
                    '%'
                }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column sortable label="单粉产出" prop="name" width="80">
              <template #default="scope">
                <span>{{ (scope.row.totalAmount / scope.row.newFans).toFixed(2) }}</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="分组" prop="deptName" width="80" /> -->
            <!-- <el-table-column label="渠道" prop="partnerPlatform" width="80" /> -->
            <el-table-column sortable label="投放日" width="90" prop="day" />
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination" style="margin-top:20px">
          <pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :total="total"
            :pagesize="pagesize"
            :currentPage="currentPage"
            :pagesizes="pagesizes"
          ></pagination>
        </div>
      </el-card>
    </div>

    <!-- 首充弹窗 -->
    <!-- totalDatas.cost总消耗 -->
    <el-dialog title="首充详情" v-model="initialDeails" width="90%">
      <div class="initialDeails flex">
        <div v-for="(i, index) in TotalRecoverys.orderCreateTime">
          <div
            class="initialDeailsitem"
            v-if="
              TotalRecoverys.sumAmount[index] / totalDatas.cost < 1 || flag == 1
            "
          >
            <p>
              第{{ TotalRecoverys.day[index] }}{{ radio1 == 0 ? '天' : '个月' }}
            </p>
            <p>{{ TotalRecoverys.orderCreateTime[index] }}</p>
            <!-- <p>
            {{ firstDiy[index].toFixed(2) }}
            ({{ ((firstDiy[index] / totalDatas.cost) * 100).toFixed(2) }}%)
          </p> -->
            <p>
              {{ TotalRecoverys.sumAmount[index].toFixed(2) }}
              ({{
                (
                  (TotalRecoverys.sumAmount[index] / totalDatas.cost) *
                  100
                ).toFixed(2)
              }}%)
            </p>
            <p>+{{ TotalRecoverys.amount[index] }}</p>
            <p style=" color: #f56c6c;">
              +{{
                (
                  (TotalRecoverys.amount[index] / totalDatas.cost) *
                  100
                ).toFixed(2)
              }}%
            </p>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 个人回收弹窗 -->
    <!-- totalDatas.cost总消耗 -->
    <el-dialog title="回收详情" v-model="WpRecoveryByDayDeails" width="90%">
      <div class="initialDeails flex">
        <div v-for="(i, index) in WpRecoveryByDayData">
          <!-- v-if="i.sumAmount / rowcost < 1 || flag == 1" -->
          <div class="initialDeailsitem">
            <p style=" color: #f56c6c;">
              第{{ i.day }}{{ radio1 == 0 ? '天' : '个月' }}
            </p>
            <p>{{ i.orderPayTime }}</p>
            <p>{{ i.count }}次/ {{ i.scrs }}人</p>
            <p>
              {{ i.sumAmount.toFixed(2) }}
              ({{ ((i.sumAmount / rowcost) * 100).toFixed(2) }}%)
            </p>
            <p>+{{ i.amount }}</p>
            <p style=" color: #f56c6c;">
              +{{ ((i.amount / rowcost) * 100).toFixed(2) }}%
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 新增数据弹窗 -->
    <el-dialog title="数据" v-model="addDataShou" width="30%">
      <!-- <div class="addDataShouSelect alcenter" v-if="type == 3">
        <div class="addDataShouSelect-name">投放类型：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="optype"
          placeholder="投放类型："
        >
          <el-option v-for="item in optypes" :key="item.id" :label="item.value" :value="item.id">
          </el-option>
        </el-select>
      </div> -->
      <div class="addDataShouSelect alcenter" v-if="type == 3">
        <div class="addDataShouSelect-name">小程序类别：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="videoType"
          placeholder="小程序类别"
        >
          <el-option
            v-for="item in options.videoType"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div
        class="addDataShouSelect alcenter"
        v-if="type == 3 && (videoType == 2 || videoType == 4)"
      >
        <div class="addDataShouSelect-name">广告账户id:</div>
        <el-input
          style="width:100%"
          :disabled="addDeleteDialog"
          clearable
          v-model="wps"
          placeholder="广告账户id"
        >
        </el-input>
      </div>
      <div class="addDataShouSelect alcenter" v-if="videoType == 1">
        <div class="addDataShouSelect-name">推广号：</div>
        <el-radio-group v-model="spread" style="width:100%">
          <el-radio :label="1" size="large">是</el-radio>
          <el-radio :label="2" size="large">否</el-radio>
        </el-radio-group>
      </div>
      <div
        class="addDataShouSelect alcenter"
        v-if="
          (type == 3 && (videoType == 2 || videoType == 4)) ||
            (spread == 1 && videoType == 1)
        "
      >
        <div class="addDataShouSelect-name">日期：</div>
        <el-date-picker
          style="width:100%"
          v-model="day"
          type="date"
          :disabled="addDeleteDialog"
          placeholder="选择日期"
          value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </div>

      <div
        class="addDataShouSelect alcenter"
        v-if="videoType == 1 || videoType == 3 || type != 3"
      >
        <div class="addDataShouSelect-name">
          {{ spread == 1 && videoType == 1 ? '推广id：' : '公众号：' }}
        </div>
        <el-input
          v-if="spread == 1 && videoType == 1"
          style="width:100%"
          clearable
          v-model="wps"
          placeholder="请输入推广id"
        >
        </el-input>
        <el-select
          style="width:100%"
          v-else
          clearable
          filterable
          v-model="addwp"
          @change="getContextFun()"
          placeholder="搜索公众号"
        >
          <el-option
            v-for="item in options.wp"
            :key="item.value"
            :label="item.id"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">投放人：</div>
        <el-select
          style="width:100%"
          filterable
          clearable
          v-model="adduserName"
          placeholder="投放人"
        >
          <el-option
            v-for="item in options.userName"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">广告平台：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="addadPlatform"
          placeholder="广告平台"
        >
          <el-option
            v-for="item in options.adPlatform"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">字节版本：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="byteVersion"
          placeholder="字节版本"
        >
          <el-option
            v-for="item in options.byteVersion"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">渠道：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="addpartnerPlatform"
          placeholder="渠道"
        >
          <el-option
            v-for="item in options.partnerPlatform"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter" v-if="type == 3">
        <div class="addDataShouSelect-name">充值模版：</div>
        <el-select
          style="width:100%"
          filterable
          v-model="typeOfAmount"
          placeholder="充值模版"
        >
          <el-option
            v-for="item in typeOfAmounts"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">书城平台：</div>
        <el-select
          style="width:100%"
          filterable
          v-model="platformName"
          placeholder="书城平台"
        >
          <el-option
            v-for="item in options.platformName"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">书名：</div>
        <el-input
          style="width:100%"
          clearable
          v-model="addbookName"
          placeholder="请输入书名"
        >
        </el-input>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">消耗：</div>
        <el-input
          style="width:100%"
          type="number"
          clearable
          v-model="addcost"
          placeholder="请输入消耗"
        >
        </el-input>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">粉丝数：</div>
        <el-input
          style="width:100%"
          type="number"
          clearable
          v-model="addnewUsers"
          placeholder="请输入粉丝数"
        >
        </el-input>
      </div>

      <div class="depart-btn flex" v-if="radio1 == 0">
        <div>
          <el-button type="success" size="mini" @click="addDataOk"
            >确定</el-button
          >
        </div>
        <div v-if="addDeleteDialog">
          <el-button type="danger" size="mini" @click="addDelete"
            >删除</el-button
          >
        </div>
        <div>
          <el-button size="mini" @click="addDataErr">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 个人数据弹窗 -->
    <el-dialog
      title="集成"
      v-model="gatherDeailsDialog"
      width="95%"
      top="0.2vh"
    >
      <integration
        @excelOutFunon="excelOutFunon"
        :IntegrationData="IntegrationData"
        :haiwaiData="haiwaiData"
        :parameterList="parameterList"
        @restart="restart"
      ></integration>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeUnmount } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import {
  getRequestList,
  totalData,
  TotalRecovery,
  getPayCost,
  tableData,
  getFillParameter,
  getContext,
  deleteContext,
  updateContext,
  tableDataByMonth,
  TotalRecoveryByMonth,
  totalDataByMonth,
  monthgetRequestList,
  getIntegrationData,
  WpRecoveryByDay,
  homePageDataExcel,
} from '@/request/new/nueDataSystem'
import pagination from '@/components/pagination.vue'
import integration from '@/components/integration.vue'
import * as echarts from 'echarts'
export default {
  name: 'mobile',
  components: { pagination, integration },
  setup() {
    // 声明myChart
    let myChart = null
    const state = reactive({
      isEnable: 1,
      isEnableArr: [
        { id: 0, value: '否' },
        { id: 1, value: '是' },
      ],
      byteVersions: [],
      byteVersion: [],
      platformNames: [],
      platformName: [],
      typeOfAmount: '0',
      typeOfAmounts: [
        { id: '0', value: '其他' },
        { id: '1', value: '小额' },
        { id: '2', value: '中额' },
        { id: '3', value: '大额' },
        { id: '4', value: 'ROI' },
        { id: '5', value: '图文消息' },
      ],
      spread: 2,
      day: '',
      // 小程序类别
      videoType: null,
      videoTypes: null,
      optypes: [
        { id: 1, value: '短剧微信小程序' },
        { id: 2, value: '短剧抖音小程序' },
      ],
      // 投放类型：
      optype: 1,
      pagesizes: [30, 50, 100, 250, 500, 1000],
      // 公众号每日回收详情
      WpRecoveryByDayData: [],
      // 公众号每日回收弹窗
      WpRecoveryByDayDeails: false,
      // 日月统计切换接口
      radio1: 0,
      // 页面接口选择
      type: 3,
      // 获取用户名称
      shujuyuanName: localStorage.getItem('userName'),
      // 获取数据员角色，不展示相关数据
      shujuyuan: localStorage.getItem('shujuyuan'),
      //获取角色权限编号
      rId: localStorage.getItem('rId'),
      //数据员这个有值才可以才看的到
      icon: localStorage.getItem('icon'),
      // 权限
      flag: localStorage.getItem('flag'),
      //获取权限编号
      r: localStorage.getItem('r'),
      icon: localStorage.getItem('icon'),
      types: [
        { value: '小说', id: 1 },
        // { value: '测试', id: 2 },
        { value: '短剧', id: 3 },
        { value: 'iaa短剧', id: 4 },
        { value: '奇树', id: 5 },
        { value: '海外', id: 6 },
        { value: '快手', id: 7 },
        { value: '短篇小说', id: 8 },
      ],
      // 是否显示删除键
      addDeleteDialog: false,
      // 选择好的参数
      // recovery: '',
      platform: [],
      userName: [],
      bookName: [],
      wp: [],
      adPlatform: [],
      typeOfAmountss: [],
      partnerPlatform: [],
      groupName: [],
      // 排序的请求字段(降序)  0是充值 1是成本
      orderby: [
        {
          value: 0,
          label: '充值',
        },
        {
          value: 1,
          label: '成本',
        },
      ],
      // 排序的请求选中的值
      orderbyValue: 0,
      // 新增填写的数据
      addwp: '',
      adduserName: '',
      addadPlatform: '',
      addpartnerPlatform: '',
      addbookName: null,
      addnewUsers: null,
      accountId: [],
      addcost: null,
      // 首页的总消耗、充值、粉丝数的数据
      totalDatas: {},
      // 总付费成本
      getPayCostDate: 0,
      // 首页的总回收率
      TotalRecoverys: {
        amount: [0],
      },
      // 回收率
      // recoverys: [
      //   { value: '0-20%', id: 0 },
      //   { value: '20-40%', id: 0 },
      //   { value: '40-60%', id: 0 },
      //   { value: '60-80%', id: 0 },
      //   { value: '80-100%', id: 0 },
      //   { value: '>100%', id: 0 },
      // ],
      // 默认参数
      getRequestLists: [],
      // 快捷时间日
      shortcuts: [
        {
          text: '上周',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '上个月',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '前三个月',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
        {
          text: '前半年',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            return [start, end]
          },
        },
        {
          text: '前一年',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            return [start, end]
          },
        },
      ],
      // 快捷时间月
      shortcutss: [
        {
          text: '本月',
          value: [new Date(), new Date()],
        },
        {
          text: '今年',
          value: () => {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)

            return [start, end]
          },
        },
        {
          text: '前六个月',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setMonth(start.getMonth() - 6)
            return [start, end]
          },
        },
      ],
      wps: [],
      // 日期
      date: [],
      // 首充弹窗
      initialDeails: false,
      // 新增数据弹窗
      addDataShou: false,
      // 个人数据集合弹窗
      gatherDeailsDialog: false,
      // 表格数据
      tableData: [],
      // 条数
      pagesize: 30,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 0,
      // 投放人下拉
      options: {},
      // 投放人、
      value1: '',
      // 首充列表
      firstDiy: [],
      // 当前成本
      rowcost: 0,
      // 集成数据
      IntegrationData: [],
      //海外要给集成组件的区别数据
      haiwaiData:{
        //判断是否启用 默认不启用
        start:false,
        //是否可以查看60天以为的 默认为不可以
        flag:0,
        //识别页面
        type:3,
      },
      //要传入集成的综合数据
      parameterList:{
        //页面的类型
        type:3,
        //页面类型  1 indexData   2 mobile 移动端暂时不上线
        pageType:2,        
      },      
      // 页面首充
      firstAmount: 0,
      //集成里面的刷新数据（只是重新请求）
      restart:()=>{
        state.gatherDeailsDialogFun()
      },      
      // 导出按钮方法
      excelOutFunon: () => {
        const excelQuery = {
          type: state.type,
          starTime: state.date[0],
          endTime: state.date[1],
          dateType: state.radio1 == 0 ? 'day' : 'month',
          userName: state.userName,
        }

        console.log('导出按钮方法', excelQuery)

        if (state.groupName == '') {
          excelQuery.groupName = []
        } else {
          excelQuery.groupName = [state.groupName[state.groupName.length - 1]]
        }
        console.log('导出的请求参数11', excelQuery)
        console.log(excelQuery.groupName, 'newgroupName')

        axios
          .post('/excel/collectData', excelQuery, {
            responseType: 'blob',
          })
          .then((response) => {
            const content = response.data
            const blob = new Blob([content])
            const titleName =
              state.type == 1 ? '小说-' : state.type == 2 ? '测试-' : '短剧-'
            const fileName =
              titleName + state.date[0] + '-' + state.date[1] + '.xlsx'

            if ('download' in document.createElement('a')) {
              const link = document.createElement('a')

              link.download = fileName
              link.style.display = 'none'

              link.href = URL.createObjectURL(blob)
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              console.log('link.href', link.href)
            } else {
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .catch((error) => console.error(error))
      },
      // 首页导出
      exportFun: () => {
        console.log('state.groupName', state.groupName)
        let groupName = state.groupName

        if (state.groupName && state.groupName.length > 0) {
          groupName = [state.groupName[state.groupName.length - 1]]
        } else {
          groupName = []
        }
        console.log('groupName', groupName)

        const data = {
          starTime: state.date[0],
          endTime: state.date[1],
          bookName: state.bookName,
          platform: state.platform,
          wp: state.wp,
          userName: state.userName,
          adPlatform: state.adPlatform,
          partnerPlatform: [],
          groupName: groupName,
          type: state.type,
          typeOfAmount: state.typeOfAmountss,
          platformName: state.platformNames,
          byteVersion: [],
          accountId: state.accountId,
          isEnable: state.isEnable,
          flag: 0,
        }

        if (state.type == 3) {
          data.videoType = state.videoTypes
          state.typeOfAmount = state.typeOfAmountss
        }
        // fetch('http://dfrt.natapp4.cc/home/excelTableData', {
        // fetch('http://wxgzhapp.natapp1.cc/home/excelTableData', {
        fetch('https://wxappvue.df01.top/api/home/excelTableData', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            authorize: 'Bearer' + localStorage.getItem('token'),
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            const titleName =
              state.type == 1 ? '小说-' : state.type == 2 ? '测试-' : '短剧-'
            const fileName =
              titleName + state.date[0] + '-' + state.date[1] + '.xlsx'

            link.download = fileName
            document.body.appendChild(link)
            link.click()
          })
          .catch((error) => console.error(error))
      },
      // 选择日期
      dateChange: () => {
        // tableDataFun()
        alldataFun()
      },
      // 清空新增内容的数据封装
      emptyFunAddData: () => {
        state.addwp = ''
        state.wps = ''
        state.adduserName = ''
        state.addadPlatform = ''
        state.addpartnerPlatform = ''
        state.addbookName = null
        state.addnewUsers = null
        state.addcost = null
        state.videoType = null
        state.typeOfAmount = '0'
        state.platformName = ''
        state.byteVersion = []
      },
      // 新增数据按钮
      addDataFun: () => {
        state.addDeleteDialog = false
        state.emptyFunAddData()
        const data = {
          starTime: state.date[0],
          endTime: state.date[1],
          type: state.type,
        }

        getFillParameter(data).then((res) => {
          state.options = res.data
          state.addDataShou = true
        })
      },
      // 取消新增
      addDataErr: () => {
        // 清空表单数据
        state.addDataShou = false
        state.emptyFunAddData()
      },
      // 确定新增
      addDataOk: () => {
        const data = {
          cost: state.addcost,
          bookName: state.addbookName,
          userName: state.adduserName,
          newUsers: state.addnewUsers,
          adPlatform: state.addadPlatform,
          partnerPlatform: state.addpartnerPlatform,
          type: state.type,
          typeOfAmount: state.typeOfAmount,
          platformName: state.platformName,
          byteVersion: state.byteVersion,
        }

        if (state.videoType != '') {
          data.videoType = state.videoType
        }
        if (
          (state.videoType == 1 && state.spread != 1) ||
          state.videoType == 3 ||
          state.type != 3
        ) {
          data.day = state.addwp.split('*')[1]
          data.wp = state.addwp.split('*')[0]
        } else {
          data.day = state.day
          data.wp = state.wps
        }

        // if (state.type == 3 && state.optype == 1) {
        //   data.videoType = 3
        // }
        for (const key in data) {
          if (data[key] === '') {
            ElMessage.error('文本内容不能为空')
            return
          }
        }
        updateContext(data).then((res) => {
          ElMessage({
            type: 'success',
            message: res.msg,
            // 提交表单
          })
          state.addDataShou = false
          // tableDataFun()
          alldataFun()
          state.emptyFunAddData()
        })
      },

      // 集成详情弹窗
      gatherDeailsDialogFun: () => {
        if (state.shujuyuan == 'shujuyuan') {
          return ElMessage({
            type: 'error',
            message: '暂未开放',
          })
        } else {
          state.parameterList.type=state.type
          state.parameterList.bookName=state.bookName
          let groupName = state.groupName

          if (state.groupName && state.groupName.length > 0) {
            groupName = [state.groupName[state.groupName.length - 1]]
          }
          const data = {
            type: state.type,
            starTime: state.date[0],
            endTime: state.date[1],
            date: state.radio1 == 0 ? 'day' : 'month',
            groupName: groupName,
            userName: state.userName,
            bookName: state.bookName,
            platformName: state.platformName,
            platform:state.platform,
          }
            //每次先初始化
            state.haiwaiData.start=false
            state.haiwaiData.flag=0
            state.haiwaiData.type=3
            // 海外页面
           if(state.type==6){
            state.haiwaiData.start=true
            state.haiwaiData.type=6
            if(state.rId==16||state.shujuyuanName == 'xieqianhui2'|| state.shujuyuanName == '董翔2'){
              //看所有
              data.flag=1
              state.haiwaiData.flag=1
            }else{
              //只能才看60天之内的
              data.flag=0
              state.haiwaiData.flag=0
            }
          }

          getIntegrationData(data).then((res) => {
            state.IntegrationData = res.data
            state.gatherDeailsDialog = true
          })
        }
      },

      // 查询内容是否填写
      getContextFun: () => {
        const data = {
          day: state.addwp.split('*')[1],
          wp: state.addwp.split('*')[0],
        }

        getContext(data).then((res) => {
          state.addbookName = res.data.bookName
          state.addnewUsers = res.data.newUsers
          state.addcost = res.data.cost
          state.adduserName = res.data.userName
          state.addadPlatform = res.data.adPlatform
          state.addpartnerPlatform = res.data.partnerPlatform
        })
      },
      // 删除该条数据
      addDelete: () => {
        const data = {
          day: state.addwp.split('*')[1],
          wp: state.addwp.split('*')[0],
        }

        deleteContext(data).then((res) => {
          ElMessage({
            type: 'success',
            message: '删除成功',
            // 提交表单
          })
          state.addDataShou = false
          state.emptyFunAddData()
          // tableDataFun()
          alldataFun()
        })
      },
      // 首充弹窗
      initialDeailsFun: () => {
        if (state.shujuyuan == 'shujuyuan') {
          ElMessage({
            type: 'error',
            message: '暂未开放',
            // 提交表单
          })
        } else {
          const data = dataFun()

            // 海外页面
            if(state.type==6){
            if(state.rId==16||state.shujuyuanName == 'xieqianhui2'|| state.shujuyuanName == '董翔2'){
              //看所有
              data.flag=1
            }else{
              //只能才看60天之内的
              data.flag=0
            }
          }

          if (state.radio1 == 0) {
            TotalRecovery(data).then((res) => {
              state.TotalRecoverys = res.data.recycle
              state.firstAmount = res.data.firstAmount

              state.firstDiy = []
              state.initialDeails = true
              initEcharts()
            })
          } else {
            TotalRecoveryByMonth(data).then((res) => {
              state.TotalRecoverys = res.data.recycle
              state.firstAmount = res.data.firstAmount

              state.firstDiy = []
              state.initialDeails = true
              initEcharts()
            })
          }
        }
        // 首页的总回收率
      },
      // 修改新增数据
      addDetailsFun: (row, column) => {
        if (column.label == '投放人') {
          const data = {
            starTime: state.date[0],
            endTime: state.date[1],
            type: state.type,
          }

          getFillParameter(data).then((res) => {
            if (row.videoType != '') {
              state.type = 3
              state.optype = 2
              state.videoType = row.videoType
              state.day = row.day
              state.wps = row.wp
            }
            state.options = res.data
            state.addDataShou = true
            state.addwp = row.wp + '*' + row.day
            state.adduserName = row.userName
            state.addadPlatform = row.adPlatform
            state.addpartnerPlatform = row.partnerPlatform
            state.addbookName = row.bookName
            state.addnewUsers = row.newFans
            state.addcost = row.cost
            state.typeOfAmount = row.typeOfAmount
            state.platformName = row.platformName
            state.addDeleteDialog = true
            state.byteVersion = row.byteVersion
          })
        } else if (column.label == '回收率' && state.radio1 == 0) {
          state.rowcost = row.cost
          const data = {
            starTime: state.date[0],
            endTime: state.date[1],
            day: row.day,
            wp: row.wp,
          }
            // 海外页面
            if(state.type==6){
            if(state.rId==16||state.shujuyuanName == 'xieqianhui2'|| state.shujuyuanName == '董翔2'){
              //看所有
              data.flag=1
            }else{
              //只能才看60天之内的
              data.flag=0
            }
          }

          WpRecoveryByDay(data).then((res) => {
            state.WpRecoveryByDayData = res.data
            state.WpRecoveryByDayDeails = true
          })
        }
      },

      // 选择小说  短剧 测试接口
      typehange: () => {
        alldataFun()
      },
      // 日月统计
      typehanges: () => {
        setCurrentDate()
        alldataFun()
      },
      // 分页开始
      // 页数 发生改变
      handleCurrentChange: (e) => {
        state.currentPage = e
        tableDataFun()
      },
      // 个数 发生改变
      handleSizeChange: (e) => {
        state.pagesize = e
        tableDataFun()
      },
      // 分页结束
    })
    // 默认参数
    const dataFun = () => {
      let groupName = state.groupName

      if (state.groupName && state.groupName.length > 0) {
        groupName = [state.groupName[state.groupName.length - 1]]
      }
      const data = {
        starTime: state.date[0],
        endTime: state.date[1],
        bookName: state.bookName,
        platform: state.platform,
        wp: state.wp,
        userName: state.userName,
        // groupName: state.groupName,
        adPlatform: state.adPlatform,
        partnerPlatform: state.partnerPlatform,
        groupName: groupName,
        size: state.pagesize,
        currentPage: state.currentPage,
        type: state.type,
        typeOfAmount: state.typeOfAmountss,
        platformName: state.platformNames,
        byteVersion: state.byteVersions,
        accountId: state.accountId,
        isEnable: state.isEnable,
      }

      if (state.type == 3) {
        data.videoType = state.videoTypes
        state.typeOfAmount = state.typeOfAmountss
      }
      return data
    }
    // 默认日期
    const setCurrentDate = () => {
      const s = new Date().getTime()
      const date = new Date(s)
      // 年
      const year = date.getFullYear()
      // 月
      const month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      // 日
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // // 时
      // const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      // // 分
      // const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // // 秒
      // const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // + ' ' + hh + ':' + mm + ':' + ss

      if (state.radio1 == 0) {
        state.date = [
          year + '-' + month + '-' + day,
          year + '-' + month + '-' + day,
        ]
      } else if (state.radio1 == 1) {
        state.date = [year + '-' + month, year + '-' + month]
      }
    }

    setCurrentDate()
    // 图表
    const initEcharts = () => {
      myChart = echarts.init(document.getElementById('barSimple'))
      const option = {
        title: {
          text: '回收率曲线',
          // subtext: '模拟数据',
          x: 'left',
        },
        xAxis: {
          type: 'category',
          data: state.TotalRecoverys.orderCreateTime,
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %',
            },
            show: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            data: state.TotalRecoverys.percent,
            type: 'line',
            smooth: true,
            // 是否直接显示数据
            // itemStyle: { normal: { label: { show: true } } },
          },
        ],
      }

      myChart.setOption(option)
    }

    // 首页下方默认表格
    const tableDataFun = () => {
      const data = dataFun()

      console.log('data', data)
      // 清空默认首充

      state.firstAmount = 0
      // 判断是否是月统计  0是日 1是月
      if (state.radio1 == 0) {
        data.flag = state.orderbyValue
        tableData(data).then((res) => {
          state.tableData = res.data.data
          state.currentPage = res.data.currentPage
          state.total = res.data.totalSize
        })
        // //首页导出 未上线！！(勿删)
        // homePageDataExcel(data).then((res) => {
        //   console.log('导出==>', res)
        // })
      } else if (state.radio1 == 1) {
        data.flag = state.orderbyValue
        tableDataByMonth(data).then((res) => {
          state.tableData = res.data.data
          state.currentPage = res.data.currentPage
          state.total = res.data.totalSize
        })
      }
    }
    // 获取首页数据 集合
    const alldataFun = () => {
      const data = dataFun()

      console.log('dataFun', data)
      console.log('localStorage', localStorage)

      // 首页下方默认表格

      tableDataFun()

      // 判断是否是月统计  0是日 1是月
      if (state.radio1 == 0) {
        // 首页的总消耗、充值、粉丝数的数据
        totalData(data).then((res) => {
          state.totalDatas = res.data
        })

        // 付费成本
        // getPayCost(data).then((res) => {
        //   state.getPayCostDate = res.data
        // })
        // 请求参数
        getRequestList({
          starTime: state.date[0],
          endTime: state.date[1],
          type: state.type,
        }).then((res) => {
          state.getRequestLists = res.data
        })
      } else if (state.radio1 == 1) {
        // 月统计的总消耗、充值、粉丝数的数据
        totalDataByMonth(data).then((res) => {
          state.totalDatas = res.data
        })
        // 月统计的总回收率

        // 默认请求参数
        monthgetRequestList({
          starTime: state.date[0],
          endTime: state.date[1],
          type: state.type,
        }).then((res) => {
          state.getRequestLists = res.data
        })
      }
    }
        //限制观察员
    const xzgcyFun=()=>{
     if(state.shujuyuan=='shujuyuan'&&(state.icon=='undefined'||state.icon=='null')) {
      state.types=[
      { value: '小说', id: 1 },
        { value: '短剧', id: 3 },
        { value: 'iaa短剧', id: 4 },
        { value: '奇树', id: 5 },
        { value: '快手', id: 7 },
      ]
     }
    }
    xzgcyFun()

    // 在组件卸载之前 注销myChart  避免切换页面无法渲染
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
    alldataFun()
    return {
      ...toRefs(state),
      xzgcyFun,
      initEcharts,
      tableDataFun,
      alldataFun,
    }
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.indexdata-top {
  margin-top: 10px;
}
/deep/ .el-input--suffix .el-input__inner {
  height: 40px !important;
}
.map {
  width: 600px;
  height: 410px;
}
.datashou {
  margin-top: 20px;
}
.data-car {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  > p {
    margin-bottom: 5px;
  }
  span {
    color: #f56c6c;
    font-size: 18px;
  }
}
.data-carfor {
  margin-top: 10px;
  span {
    // display: block;
    display: inline-block;
    margin-right: 20px;
    width: 100px;
    margin-top: 10px;
    height: 80px;
    // cursor: pointer;
    p {
      font-size: 18px;
      text-align: center;
    }
  }
}

.index-top-right {
  margin-left: 20px;
  .map {
    width: 700px;
  }
}
.indexdata-bot {
  // margin-bottom: 40px;
  max-width: 1600px;
}
.indexdata-bot-select {
  margin-left: 10px;
  width: 160px;
}
.indexdata-bot-top {
  margin-bottom: 10px;
  // justify-content: space-between;
}
.initialDeails {
  flex-wrap: wrap;
}
.initialDeailsitem {
  width: 180px;
  border: thin solid rgba(0, 0, 0, 0.22);
  // height: 140px;
  box-sizing: border-box;
  padding: 6px 0;
  padding-left: 15px;
  p {
    margin-top: 6px;
    font-size: 15px;
  }
}

.searchbtn {
  margin-left: 20px;
}
.addDataShouSelect {
  width: 400px !important;
  margin-top: 20px;
}
.addDataShouSelect-name {
  width: 150px;
  text-align: right;
}
.depart-btn {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  > div {
    margin-left: 20px;
  }
}
</style>
